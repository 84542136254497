.page_menu {
    font-size: 16px;
    background-color: #b9e5fb;
    // border-bottom: 20px solid black;
    overflow: hidden;
    .wrapper {
        height: 60px;
        max-height: 60px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        padding: 15px 0;
        justify-content: space-between;
        margin: auto;
        @media only screen and (width > 1024px) {
            max-width: 1024px;
        }
    }
    .left {
        padding-left: 12px;
        @media only screen and (width > 1024px) {
            padding-left: 0;
        }
        display: flex;
        align-content: center;
        height: 60px;
        #menu_trigger {
            background-image: url("/images/menu-trigger.svg");
            &.close {
                background-image: url("/images/menu-close-trigger.svg");
            }
            background-repeat: no-repeat;
            // background-attachment: fixed;
            background-position: center;
            display: flex;
            align-content: center;
            width: 24px;
            margin-right: 12px;
            cursor: pointer;
            @media only screen and (width > 1024px) {
                display: none;
            }
        }
        .branding {
            a {
                
                img {
                    margin-top: 8px;
                    margin-left: 6px;
                    height: 48px;
                }
            }
        }
    }
    .center {
        flex-grow: 4;
        display: none;
        @media only screen and (width > 1024px) {
            // display: block;
            // align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            line-height: 100%;
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            .c_left {
                padding-top: 14px;
                font-weight: 700;
                a {
                    
                    margin-right: 18px;
                }
            }


        }
        margin: auto;
        text-align: center;
        span {
            font-size: 20px;
            font-weight: 600;
        }
    }
    .right {
        // NAVIGATION
        @media only screen and (width > 1024px) {
            display: flex;
            align-items: center;
        }
        .navigation {
            top: 60px;
            left: -400px;
            z-index: 1;
            // display: flex;
            flex-direction: column;
            background-color: #b9e5fb;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            min-height: 100vh;
            position: absolute;
            display: none;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease;
            @media only screen and (width > 1024px) {
                display: flex !important;
                flex-direction: row;
                animation-duration: unset;
                animation-fill-mode: unset;
                animation-timing-function: unset;
                position: unset;
                min-height: auto;
                box-shadow: unset;
            }
            ul {
                display: flex;
                flex-direction: column;
                width: 275px;
                @media only screen and (width > 1024px) {
                    flex-direction: row;
                    width: auto;
                }
                
                li {
                    color: $seccolor;
                    padding: 17px;
                    border-bottom: 2px solid $gary-l;
                    font-weight: 900;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    cursor: pointer;
                    @media only screen and (width > 1024px) {
                        border-bottom: none;
                        font-size: 12px;
                        letter-spacing: 1px;
                        display: flex;
                        align-items: center;
                        padding: 17px 12px;
                    }
                    
                    ul {
                        display: none;
                    }
                }
            }
            &.active {
                display: block;
                animation-name: showMenu;
            }
        }

        // LANGS
        .langs {
            display: flex;
            align-items: center;
            height: 60px;
            color: rgb(111, 110, 105);
            font-weight: 900;
            font-size: 12px;
            // padding-top: 12px;
            margin-right: 12px;
            // margin-top: 8px;
            // min-height: 40px;
            cursor: pointer;
            @media only screen and (width > 1024px) {
                margin-right: 0;
            }
            .active {
                color: black;
            }
            span {
                margin-right: 6px;
                margin-left: 6px;
            }

        }

    }

    // ANIMATIONS
    @keyframes showMenu {
        0%   {left:-400px; }
        // 25%  {left:-100px; }
        // 50%  {left:-50px; }
        // 75%  {left:-25px; }
        100% {left:0px; }
      }
    @keyframes hideMenu {
        0%   {left:0px; }
        // 25%  {left:-100px; }
        // 50%  {left:-50px; }
        // 75%  {left:-25px; }
        100% {left:-400px; }
    }
}