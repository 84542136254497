.page_footer {
    background-color: $gary-d;
    padding: $basepadding $basepadding 70px $basepadding;
    color: white;

    .wrapper {
        max-width: 1024px;
        margin: auto;
        @media only screen and (width > 600px) {
            display: flex;
            justify-content: space-between;
            div {
                width: 30%;
            }
        }
        .fheader {
            .branding {
                display: block;
                a {
                    img {
                        width: 190px;
                        @media only screen and (width > 600px) {
                            min-width: 180px;
                            margin-bottom: calc($basepadding);
                        }
                    }
                }
            }
        }
        .separator {
            min-height: 16px;
            max-width: 66%;
            padding:  7px 0;
            margin: $basepadding 0;
            hr {
                border: 1px dashed white;
            }
            @media only screen and (width > 600px) {
                display: none;
            }
        }
        .block {
            @media only screen and (width > 600px) {
                padding-left: $basepadding;
                border-left: 2px dotted white;
            }
            h3 {
                font-size: 120%;
                font-weight: 600;
                margin-bottom: calc($basepadding/2);
            }
            ul {
                li {
                    margin-bottom: calc($basepadding / 3);
                }
            }
        }
    }
    
}

