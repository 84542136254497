html {
  overflow-y: scroll;
  // margin-left: ;
  margin-right: 0;
}
// @media screen and (min-width: 600px) {
//   html {
//     margin-left: calc(100vw - 100%);
//     margin-right: 0;
//   }
// }

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    position: relative;
    width: 100%;

    h1{
      font-size: 1.7rem;  
    }
    .container {
      max-width: 1024px;
      margin: auto;
    }

    .site_cta {
      position: fixed;
      top: calc(100vh - 50px);
      background-color: $primcolor;
      border: 2px solid white;
      display: flex;
      width: 100vw;
      margin: auto;
      flex-direction: row;
      text-align: center;
      cursor: pointer;
      @media only screen and (width > 600px) {
        left: 50%;
        transform: translateX(-50%);
        max-width: 500px;
      }
      @media only screen and (width > 1074px) {
        left: unset;
        transform: translateX(0);
        max-width: 50px;
        flex-direction: column;
        top: 50%;
        transform: translatey(-50%);
        right: 0;
      }
      .block {
        height: 50px;
        display: block;
        margin: auto;
        width: 33%;
        @media only screen and (width > 1074px) {
          width: 100%;
        }
        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 50px;
          img {
            margin: auto;
            @media only screen and (width > 1074px) {
              width: 24px;
            }
          }
        }
      }
      .b1, .b2 {
        border-right: 2px solid white;
        @media only screen and (width > 1074px) {
          border-right: none;
          border-bottom: 2px solid white ;
        }
      }
    }
}