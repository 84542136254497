a {
    text-decoration: none;
    color: inherit;
}
ul {
    list-style-type: none;
}
html {
    line-height: 100%;
}

.main_container {
    min-height: 50vh;
}

.succesimage {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 50px;
    img {
        margin-left: auto;
        margin-right: auto;
        width: 175px;
    }
}