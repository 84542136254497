.scroll-1 {
    font-size: 14px;
    background-color: #b9e5fb;
    padding-top: 30px;
    // line-height: 150%;
    @media only screen and (width > 1024px) {
        padding-top: calc((100vh / 2) - (100vh / 3));
        // padding-top: 40px;
    }
    
    min-height: calc(100vh - 60px);
    .base_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media only screen and (width > 1024px) {
            flex-direction: row;
        }
        // div {
        //     max-width: 48%;
        //     width: 48%;
        // }
    }
    .btn_cta {
        margin: 38px auto 0 auto;
        @media only screen and (width > 1024px) {
            margin: 20px auto 0 15px;
        }
    }
}
.base_wrapper {
    max-width: 1024px;
    margin: 0px auto 0px auto;
}

.base_left_text {
    width: 100%;
    font-size: 1.1em;
    line-height: 125%;
    @media only screen and (width > 1024px) {
        width: 60%;
    }
    padding-left: 20px;
    padding-right: 20px;
    h3 {
        font-size: 1.2em;
        font-weight: 700;
        color: #676667;
        letter-spacing: 130%;
        @media only screen and (width > 1024px) {
            font-size: 1.3em;
        }
    }
    h1 {
        margin-top: 5px;
        line-height: 110%;
        font-size: 2.2em;
        font-weight: 800;
        color: #33050b;
        letter-spacing: 130%;
        @media only screen and (width > 1024px) {
            font-size: 2.7em;
        }
    }
    h6 {
        font-weight: 300;
        font-size: 1.2em;
        text-wrap: balance;
        letter-spacing: 130%;
        @media only screen and (width > 1024px) {
            font-size: 1.5em;
        }
    }
    p {
        margin-top: 15px;
        font-size: 1.2em;
        text-wrap: balance;
    }
    ul {
        margin-top: 15px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        li {
            padding-left: 15px;
            font-size: 1.1em;
            margin-bottom: 5px;
            text-wrap: balance;
            // list-style-type: disc;
            
        }
    }
    .discsvg {
        max-width: 12px;
        max-height: 12px;
        margin-right: 16px;
    }
    
    
}

.base_right_img {
    // position: absolute;
    
    @media only screen and (width < 1024px) {
        height: 325px;
    }
    min-height: 100%;
    align-items: center;
    text-align: center;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    @media only screen and (width > 1024px) {
        width: 38%;
        // height: 100%;
    }
    svg {
        width: 80%;
        height: auto;
        position: relative;
        @media only screen and (width > 1024px) {
            width: 80%;
        }
        top: 15px;
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 1s;
        animation-fill-mode: both;
        will-change: transform;
        animation-duration: 2000ms;
    }

}

@keyframes pulse {
    from {
        transform: scale3d(1,1,1)
    }

    50% {
        transform: scale3d(1.05,1.05,1.05)
    }

    to {
        transform: scale3d(1,1,1)
    }
}

// .coloana-2 {
//     @media only screen and (width < 768px) {
//         margin-top: 48px;
//     }
// }

// SCROLL 2

.scroll-2 {

    h2 {
        font-size: 1.8rem;
        text-align: center;
        width: 80%;
        margin: 12px auto 24px auto;
        font-weight: 700;
    }
    h3 {
        font-size: 1.4rem;
        text-align: center;
        // margin: 12px auto 24px auto;
        font-weight: 700;
        color: #808080;

    }
    h4 {
        color: #e81d38;
        font-size: 1.4rem;  
        font-weight: 700;
        padding-top: 1.5rem;
        margin-bottom: 0.2rem;
        text-align: center;
     }
     p {
        font-size: 1.1rem;
        color: #333333;
        font-weight: 700;
        text-align: center;
        margin-bottom: 3px;
     }
     .base_wrapper {
        padding-top: 2rem;
        padding-bottom: 2rem;
     }
     .btn_cta {
        margin: 38px auto 0 auto;
    }
}

.scroll-3 {
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: #fefee9;
    h3 {
        font-size: 1.4rem;
        text-align: center;
        width: 80%;
        margin: 12px auto 24px auto;
        font-weight: 500;
        line-height: 150%;
        span {
            font-weight: 700;
            color: #111111;
            em {
                font-style: oblique;
            }
        }
    }
    .price_card {
        background-color: white;
        max-width: 325px;
        margin: 0 auto;
        text-align: center;
        padding: 42px 25px;
        border: double 4px #e81d38;
        border-radius: 24px;
        color: #e81d38;
        h4 {
            font-size: 1.8rem;
            font-weight: 800;
            color: #b9e5fb;
            margin-bottom: 24px;
            text-transform: uppercase;
            text-shadow: 3px 3px 4px #333333;
        }
        h5 {
            font-size: 3.0rem;
            font-weight: 900;
        }
        h6 {
            font-size: 1.8rem;
            font-style: oblique;
            font-weight: 700;
        }
    }
    .btn_cta {
        margin: 38px auto 0 auto;
    }
}

.scroll-4 {
    background-color: #b9e5fb;
    padding-top: 24px;
    padding-bottom: 48px;

    h3 {
        font-size: 1.8rem;
        text-align: center;
        width: 80%;
        margin: 12px auto 24px auto;
        font-weight: 500;
    }
    h5 {
        font-size: 1.4rem;
        text-align: center;
        width: 80%;
        margin: 24px auto;
        font-weight: 500;
    }
    .steps {
        margin-top: 36px;
        margin-bottom: 36px;
        display: flex;
        flex-direction: column;
        @media only screen and (width > 1024px) {
            flex-direction: row;
        }
        gap: 24px;
        justify-content: space-between;
        font-size: 1.6rem;
        div {
            max-width: 90%;
            margin: 12px auto;
            @media only screen and (width > 1024px) {
                max-width: 30%;
            }
            border: 6px double #e81d38;
            padding: 16px 0px 24px 24px;
            border-radius: 24px;
          h4 {
            font-weight: 800;
            color: #e81d38;
            margin-bottom: 12px;
            span {
                color: #111111;
                font-weight: 900;
                font-size: 2.4rem;
            }
          }
          p {
            font-style: oblique;
            font-weight: 500;
            font-size: 1.4rem;
            // width: 80%;
          }
        }
    }
    .btn_cta {
        margin: 12px auto 0 auto;
    }
}

.scroll-5 {
    text-align: center;
    padding-top: 36px;
    padding-bottom: 76px;
    h3 {
        font-size: 1.4rem;
        text-align: center;
        width: 80%;
        margin: 12px auto 6px auto;
        font-weight: 500;
    }
    h4 {
        font-size: 2.4rem;
        color: #e81d38;
        text-align: center;
        width: 80%;
        margin: 6px auto 24px auto;
        font-weight: 500;
    }
    .btn_cta {
        margin: 0 auto;
    }
    .price_card {
        background-color: white;
        max-width: 325px;
        margin: 0 auto;
        text-align: center;
        padding: 42px 25px;
        border: double 4px #e81d38;
        border-radius: 24px;
        color: #e81d38;
        h4 {
            font-size: 1.8rem;
            font-weight: 800;
            color: #b9e5fb;
            margin-bottom: 24px;
            text-transform: uppercase;
            text-shadow: 3px 3px 4px #333333;
        }
        h5 {
            font-size: 3.0rem;
            font-weight: 900;
        }
        h6 {
            font-size: 1.8rem;
            font-style: oblique;
            font-weight: 700;
        }
    }
}

// OTHER ================================================================================ //
.coloane {
    column-count: 1;
    column-count: 3;
    column-gap: 40px;
}

.text-color1 {
    color: #e81d38;
}

.btn_cta{
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    display: table;
    cursor: pointer;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    text-decoration: none;
    border: 0 none transparent;
    outline-width: 0;
    font-style: initial;
    white-space: nowrap;
    user-select: none;
    vertical-align: middle;
    text-align: center;
    --border-radius: 0;
    align-self: flex-start;
    position: relative;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: none;
    font-size: 1.0625rem;
    font-weight: 700;
    border-radius: 8px;
    background-color: #e81d38;
    color: #ffffff;
    padding: 15px 54px 17px;
    pointer-events: initial;
}
b {
    font-weight: 700;
}
i {
    font-style: oblique;
}

.desktop {
    display: none;
    @media only screen and (width > 720px) {
        display: block;
    }
}
.mobile {
    display: block;
    padding: 12px;
    
    p {
        font-weight: 400;
        strong {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 7px;
            font-weight: 700;
        }
    }
    @media only screen and (width > 720px) {
        display: none;
    }
}

footer {
    background-color: black;
    color: white;
    .base_wrapper {
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }
    .footer_left {
        text-align: left;
        p {
            margin-bottom: 5px;
        }
    }
    .footer_right {
        h4 {
            font-weight: 700;
            margin-bottom: 5px;
        }
        text-align: right;
    }
}