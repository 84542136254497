// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// style.scss
// @import 'pub/_reset';
// @import 'pub/_variables', 'pub/_page_menu', 'pub/_page_content','pub/_page_footer', 'pub/_page', 'pub/_modal', 'pub/_form', 'pub/scroll1';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'pub/_variables', 'pub/_page_menu', 'pub/_page_content', 'pub/_page_footer', 'pub/_page', 'pub/scroll1';
@import 'pub/_corectura';

