.page_content {
    // background-color: bisque;
    min-height: 100vh;
    padding-bottom: 15px;
    
    .front_top_content {
        padding: $basepadding;
        padding-bottom: calc($basepadding*2);
        @media only screen and (width > 600px) {
            padding-bottom: $basepadding;
        }
        border-radius: 0px 0px 40px 0px;
        background: linear-gradient(180deg, #A2E8E5 0%, #E5F6FF 100%);
        .container {
            display: flex;
            .left {
                margin-bottom: $basepadding;
                @media only screen and (width > 600px)  {
                    width: 50%;

                    // background-color: red;
                }
            }
            .right {
                display: none;
                width: 50%;
                max-height: 250px;
                overflow: hidden ;
                @media only screen and (width > 600px)  {
                    display: block;
                }
                .anim_container {
                    display: flex;
                    top: -35px;
                    flex-direction: row;
                    transform: rotate(-10deg);
                    position: relative;
                    .anim_col {
                        padding-left: 10px;
                        width: 150px;
                        margin-right: $basepadding;
                        .block {
                            background-color: white;
                            min-height: 75px;
                            padding: 10px;
                            margin-bottom: $basepadding;
                            filter: drop-shadow(1px 1px 3px #9f9f9f);
                            h3 {
                                font-size: 100%;
                                font-weight: 700;
                            }
                            p {
                                font-size: 80%;
                            }
                        }
                    }
                    .col1 {
                        left: 0;
                        // background-color: #F9FFA9;
                        position: absolute;
                        top: 0%;
                        left: 0px;
                        animation-name: topbottom;
                        animation-duration: 14s;
                        animation-direction: linear;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: infinite;
                    }
                    .col2 {
                        left: 0;
                        // background-color: #F9FFA9;
                        position: absolute;
                        top: 0%;
                        left: 165px;
                        animation-name: bottomtop;
                        animation-duration: 14s;
                        animation-direction: linear;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: infinite;
                    }
                    .col3 {
                        left: 0;
                        // background-color: #F9FFA9;
                        position: absolute;
                        top: 0%;
                        left: 330px;
                        animation-name: topbottom;
                        animation-duration: 14s;
                        animation-direction: linear;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: infinite;
                    }
                }
            }
        }
// animate Frontpage TOP
@keyframes topbottom {
    0%   {top: 0px;}
    50%  {top: -87px;}
    100% {top: 0px;}
}
@keyframes bottomtop {
    0%   {top: -87px;}
    50%  {top: 0px;}
    100% {top: -87px;}
}
        h1 {
            max-width: 70%;
            color: $primcolor;
            font-weight: 800;
        }
        .text {
            max-width: 90%;
            margin: 12px 0;
            padding-bottom: $basepadding;
        }
        .cta {
            min-height: 50px;
            border-radius: 10px;
            padding: 8px 16px ;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            letter-spacing: 2px;
            font-weight: 700;
            color: $primcolor;
        }

    }

    // TESTEMONIALS
    .content_testimonial {
        position: relative;
        margin-top: $basepadding;
        padding: $basepadding;
        padding-bottom: calc($basepadding * 2);
        border-radius: 40px 0px 0px 0px;
        background: rgb(243,251,251);
        background: linear-gradient(0deg, rgba(243,251,251,1) 0%, rgba(164,229,228,1) 100%);

        h1 {
            max-width: 80%;
            margin: auto;
            text-align: center;
            color: $primcolor;
            font-weight: 800;
            margin-bottom: $basepadding;
        }
        .testemoniale {
            display: flex;
            justify-content: space-around;
            @media only screen and (width > 600px)  {
                padding-left: $basepadding;
                padding-right: $basepadding;
            }

            .testimonial {
                background-color: white;
                padding: $basepadding;
                margin-inline: auto;
                margin-bottom: calc($basepadding/2);
                border-radius: $basepadding;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .citat {
                    position: relative;
                    text-align: center;
                    max-width: 90%;
                    margin: auto;
                }
                .citat::before{
                    position: absolute;
                    top: -12px;
                    left: -18px;
                    color: $oranj;
                    font-size: 48px;
                    content: '"';
                }
                .citat::after{
                    position: absolute;
                    bottom: -32px;
                    right: -18px;
                    color: $oranj;
                    font-size: 48px;
                    content: '"';
                }
    
                .person {
                    margin-left: auto;
                    margin-top: $basepadding;
                    display: flex;
                    justify-content: flex-end;
                    .text{
                        color: $gary-d;
                        font-weight: 600;
                        text-align: right;
                    }
                    img {
                        margin-left: calc($basepadding/2);
                        border-radius: 50%;
                        max-height: 50px;
    
                    }
                }
            }
            .t1 {
                display: flex;
            }
            .t2 {
                display: none;
                @media only screen and (width > 600px)  {
                    display: flex;
                }
            }
            .t3 {
                display: none;
                @media only screen and (width > 1024px)  {
                    display: flex;
                }
            }
            .t4 {
                display: none;
            }

        }

        .left_arraw {
            position: absolute;
            top: 210px;
            left: 20px;
            display: flex;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
            img {
                position: relative;
                left: -2px;
                margin: auto;
            }
        }
        .right_arraw {
            position: absolute;
            top: 210px;
            right: 20px;
            display: flex;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
            img {
                position: relative;
                left: 2px;
                margin: auto;
            }
        }

    }

    // Despre Platformă
    .info_about {
        margin-top: $basepadding;
        margin-bottom: $basepadding;
        .wrapper {
            @media only screen and (width > 600px)  {
                display: flex;
                align-items: stretch;
            }
        }
        
        h2 {
            padding: $basepadding;
            color: $primcolor;
            font-size: 150%;
            font-weight: 700;
            width: 80%;
            line-height: 110%;
            margin-bottom: $basepadding;
            @media only screen and (width > 600px) {
                text-align: center;
                width: 100%;
                font-size: 170%;
            }
        }
        .block {
            padding: $basepadding;
            font-size: 110%;
            @media only screen and (width > 600px) {
                inset-inline: $basepadding * 2 ;
            }
        }
        .b1 {
            width: 90%;
            border-radius: 0 $basepadding $basepadding 0 ;
            background: rgb(251,247,254);
            background: linear-gradient(0deg, rgba(251,247,254,1) 0%, rgba(242,223,254,1) 100%);
            margin-bottom: $basepadding;
            @media only screen and (width > 600px)  {
                width: 49%;
                margin-bottom: 0;
            }
            @media only screen and (width > 1024px)  {
                padding-left: calc(50% - 512px);
            }
            p {
                width: 95%;
                font-size: 110%;
                font-weight: 500;
                &:not(:last-child)  {
                    margin-bottom: $basepadding;
                }
            }
        }
        .b2 {
            width: 90%;
            margin-left: auto;
            text-align: right;
            padding-right: $basepadding ;
            border-radius: $basepadding 0 0  $basepadding;
            background: rgb(243,246,211);
            background: linear-gradient(0deg, rgba(243,246,211,1) 0%, rgba(249,255,169,1) 100%);
            @media only screen and (width > 600px)  {
                width: 49%;
            }
            @media only screen and (width > 1024px)  {
                padding-right: calc(50% - 512px);
            }
            p {
                width: 95%;
                margin-left: auto;
                font-size: 110%;
                font-weight: 500;
                &:not(:last-child)  {
                    margin-bottom: $basepadding;
                }
            }
        }
        .block_cta {
            background-color: white;
            margin-top: calc($basepadding * 2);
            margin-bottom: calc($basepadding * 2);
            text-align: center;

            .cta {
                margin-inline: auto;
                min-height: 50px;
                border-radius: 10px;
                padding: 8px 16px ;
                background: $seccolor;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                letter-spacing: 2px;
                font-weight: 700;
                color: white;
            }
        }
    }

    // for who
    .for_who {
        background-color: $seccolor;
        text-align: center;
        padding: $basepadding;
        .wrapper {
            max-width: 1024px;
            margin: auto;
            @media only screen and (width > 600px) {
                display: flex;
                justify-content: space-around;
            }
            @media only screen and (width > 600px)  {
                .b3 {
                    display: none;
                }
            }
            @media only screen and (width > 1024px)  {
                .b3 {
                    display: block;
                }
            }
        }
        h3 {
            margin: auto;
            line-height: 115%;
            letter-spacing: 1px;
            width: 80%;
            color:
            
            white;
            font-size: 150%;
            font-weight: 700;
            margin-bottom: $basepadding;
        }
        .textout {
            margin: auto;
            font-size: 110%;
            font-weight: 500;
            width: 80%;
            margin-bottom: $basepadding;
        }
        .block {
            width: 300px;
            margin-inline: auto;
            background-color: white;
            margin-bottom: $basepadding;
            padding: $basepadding;
            img {
                margin-bottom: calc($basepadding/2);
            }
            .text {
                font-weight: 600;
                font-size: 120%;
                width: 85%;
                margin: auto;
            }
        }
    }
    .for_who_end {
        text-align: center;
        background-image: url("/images/cyan-block-end.svg");
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: -1px;
        background-size: cover;
        position: relative;
        top: -3px;
        min-height: 30px;
        margin-bottom: $basepadding;
        @media only screen and (width > 600px) {
            background-position-y: -8px;
        }
        @media only screen and (width > 920px) {
            background-position-y: -22px;
        }
        .cta {
            min-height: 50px;
            border-radius: 10px;
            padding: 8px 16px ;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            letter-spacing: 2px;
            font-weight: 700;
            color: $primcolor;
        }
    }

    // WHY
    .why {
        padding: $basepadding, calc($basepadding * 2 ), calc($basepadding / 2), calc($basepadding * 2);
        overflow: hidden;
        width: 100%;

        h2 {
            padding: $basepadding ($basepadding * 2);
            color: $primcolor;
            font-size: 180%;
            letter-spacing: 1px;
            font-weight: 700;
            width: 90%;
            line-height: 110%;
            // margin-bottom: $basepadding;
            @media only screen and (width > 600px) {
                text-align: center;
                width: 100%;
                font-size: 200%;
            }
        }
        .wrapper {
            max-width: 1024px;
            margin: auto;
            padding: 0 calc($basepadding * 2);
            @media only screen and (width > 600px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        .block {
            position: relative;
            @media only screen and (width > 600px) {
                width: 50%;
            }
            .number {
                font-size: 48px;
                font-weight: 600;
                color: $seccolor;
            }
            .text {
                width: 85%;
                strong {
                    font-weight: 700;
                }
                ul {
                    margin-left: $basepadding;
                }
            }
            .circle {
                position: absolute;
                display: block;
                height: 52px;
                width: 52px;
                border-radius: 50%;
                z-index: -100;
                left: -70px;
                top: 7px;
            }
        }
        .b2, .b4 {
            .circle { 
                animation-name: rleft;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
            &:hover {
                .circle {
                    animation-name: rright;
                    animation-duration: 1s;
                    right: -24px;
                }
            } 
            .text {
                margin-left: auto;
            }
            text-align: right;
        }
        .block_cta {
            background-color: white;
            margin-top: calc($basepadding * 2);
            margin-bottom: $basepadding;
            text-align: center;
            .cta {
                margin-inline: auto;
                min-height: 50px;
                border-radius: 10px;
                padding: 8px 16px ;
                background: $seccolor;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                letter-spacing: 2px;
                font-weight: 700;
                color: white;
            }
        }

        .b1 {
            .circle {
                background-color: #FECBD2;  
                animation-name: right;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
            &:hover {
                .circle {
                    animation-name: left;
                    animation-duration: 1s;
                    left: -24px;
                }
            } 
        }
        .b3 {
            .circle {
                background-color: #A2E8E5;  
                animation-name: right;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
            &:hover {
                .circle {
                    animation-name: left;
                    animation-duration: 1s;
                    left: -24px;
                }
            } 
        }
        .b5 {
            .circle {
                background-color: #F9FFA9; 
                opacity: 0;
                animation-name: right;
                animation-duration: 1s; 
                animation-fill-mode: forwards;
            }
            &:hover {
                .circle {
                    animation-name: left;
                    animation-duration: 1s;
                    left: -24px;
                }
            } 
            @media only screen and (width > 600px) {
                margin: auto;
            }
        }
        .b2 {
            .circle {
                background-color: #f8be7a; 
               left: unset;
               right: -68px;
            }
            &:hover {
                .circle {
                    right: -20px;
                }
            } 
        }
        .b4 {
            .circle {
                background-color: #b6ffbd; 
               left: unset;
               right: -68px;
            }
            &:hover {
                .circle {
                    right: -20px;
                }
            } 
        }


    }
}

@keyframes left {
    from { opacity: 0; left: -70px;}
    to {opacity: 1; left: -24px;}
  }

@keyframes right {
from { opacity: 1; left: -24px;}
to {opacity: 0; left: -70px;}
}

@keyframes rleft {
    from { opacity: 1; right: -24px;}
    to {opacity: 0; right: -70px;}
}

@keyframes rright {
    from { opacity: 0; right: -70px;}
    to {opacity: 1; right: -24px;}
}

.city_container {
    // background-color: #9f9f9f;
    display: flex;
    flex-direction: column;
    @media only screen and (width > 600px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    @media only screen and (width > 1024px) {
        max-width: 1024px;
        margin: auto;
    }
    .city_block {

        background-color: white;
        margin: $basepadding;
        padding: $basepadding;
        border-radius: $basepadding;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        @media only screen and (width > 600px) {
            width: 45%;
        }
        @media only screen and (width > 1024px) {
            width: 30%;    
        }
        h2 {
            color: $primcolor;
            font-weight: 700;
            font-size: 130%;
            margin-bottom: calc($basepadding / 2);
            @media only screen and (width > 600px) {
                text-align: center;
            }
        }
        p {
            line-height: 120%;
            margin-bottom: calc($basepadding / 2);
        }
        .block_cta {
            background-color: white;
            margin-top: $basepadding;
            margin-bottom: calc($basepadding / 2);
            text-align: right;
            @media only screen and (width > 600px) {
                text-align: center;
            }
            .cta {
                margin-inline: auto;
                min-height: 50px;
                border-radius: 10px;
                padding: 8px 16px ;
                background: $seccolor;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                letter-spacing: 1px;
                font-weight: 700;
                color: white;
            }
        }

    }
}

